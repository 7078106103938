import { Injectable } from '@angular/core';
import * as CidaasSDK from 'cidaas-javascript-sdk';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CidaasService {
    cidaas: any;
    // Setup Cidaas Options
    env = {
        authority: environment.loginBaseURL,
        client_id: environment.clientId,
    };

    constructor() {
        this.init();
    }

    postForm(path, params, method) {
        method = method || 'post';

        const form = document.createElement('form');
        form.setAttribute('method', method);
        form.setAttribute('action', path);

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);

                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    }

    // Initialize Cidaas SDK
    init() {
        this.cidaas = new CidaasSDK.WebAuth(this.env);
    }

    // Get Request Id if Request Id is not came in URL
    getRequestId(): Promise<any> {
        return this.cidaas.getRequestId();
    }

    getTenantInfo(): Promise<any> {
        return this.cidaas.getTenantInfo();
    }

    // Login to Myaccount
    login(user: any): void {
        this.cidaas.loginWithCredentials({
            username: user.email,
            usernameType: 'email',
            password: user.password,
            requestId: user.requestId,
        });
    }

    // Login to Myaccount
    loginAfterRegister(trackId: any): void {
        this.cidaas.loginAfterRegister({
            trackId,
        });
    }

    // First time Change Password (When first time change password flag is enabled)
    firstTimeChangePassword(user) {
        this.cidaas.firstTimeChangePassword(user);
    }

    // Change User Password
    changePassword(password, token) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .changePassword(
                    {
                        oldPassword: password.oldPassword,
                        newPassword: password.newPassword,
                        confirmPassword: password.confirmPassword,
                        identityId: password.identityId,
                    },
                    token
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Initiate User Account Verification
    initiateAccountVerification(options, verificationMedium: 'email' | 'sms' = 'email', processingType: 'LINK' | 'CODE' = 'LINK') {
        this.postForm(
            'https://rehau-migration-prod.cidaas.eu/verification-srv/account/initiate',
            {
                verificationMedium,
                requestId: options.requestId,
                processingType,
                sub: options.sub,
            },
            'POST'
        );
    }

    verifyAccount(options: { accvid: string; code: string }): Promise<any> {
        return this.cidaas.verifyAccount(options);
        // .then((response) => {
        //     console.log(response);
        //     getUserEmail.next(response);
        // })
        // .catch((ex) => {
        //     console.log(ex);
        // });
    }

    // Get User Consent Details
    getConsentDetails(options) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .getConsentDetailsV2(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Accept User Consent
    acceptConsent(options) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .acceptConsentV2(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((ex) => {
                    console.log(ex);
                    reject(ex);
                });
        });
    }

    // Continue After User Accept Consent
    consentContinue(user) {
        this.cidaas.consentContinue({
            client_id: user.client_id,
            trackIds: user.trackId,
            version: user.version,
            sub: user.sub,
            name: user.name,
        });
    }

    // Get MFA List
    getMFAList(options) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .getMFAListV2(options)
                .then((response) => {
                    resolve(response);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    getConfiguredList(options, token) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .getConfiguredVerificationList(options, token)
                .then((response) => {
                    resolve(response);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Get All Allowed MFA List
    getAllowedMFAList(requestId) {
        return new Promise((resolve, reject) => {
            try {
                const http = new XMLHttpRequest();

                const _serviceURL = this.env.authority + '/public-srv/public/' + requestId;
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        if (http.responseText) {
                            resolve(JSON.parse(http.responseText));
                        } else {
                            resolve(false);
                        }
                    }
                };
                http.open('GET', _serviceURL, true);
                http.setRequestHeader('Content-type', 'application/json');
                http.send();
            } catch (ex) {
                reject(ex);
            }
        });
    }

    // Enable or Disable User MFA
    enableOrDisableMFA(options, token) {
        return new Promise((resolve, reject) => {
            try {
                const http = new XMLHttpRequest();

                const _serviceURL = this.env.authority + '/users-srv/user/profile/' + options.sub.toString();
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        if (http.responseText) {
                            resolve(JSON.parse(http.responseText));
                        } else {
                            resolve(false);
                        }
                    }
                };
                http.open('PUT', _serviceURL, true);
                http.setRequestHeader('Content-type', 'application/json');
                http.setRequestHeader('access_token', token);
                http.send(JSON.stringify(options));
            } catch (ex) {
                reject(ex);
            }
        });
    }

    // Delete User Profile Info
    deleteUserProfile(sub, token) {
        return new Promise((resolve, reject) => {
            try {
                const http = new XMLHttpRequest();
                const _serviceURL = this.env.authority + '/users-srv/user/unregister/' + sub.toString();
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        if (http.responseText) {
                            resolve(JSON.parse(http.responseText));
                        } else {
                            resolve(false);
                        }
                    }
                };
                http.open('DELETE', _serviceURL, true);
                http.setRequestHeader('Content-type', 'application/json');
                http.setRequestHeader('access_token', token);
                http.send();
            } catch (ex) {
                reject(ex);
            }
        });
    }

    // get missing fields details
    getMissingFields(trackId) {
        return new Promise((resolve, reject) => {
            try {
                const http = new XMLHttpRequest();
                const _serviceURL = environment.loginBaseURL + '/token-srv/prelogin/metadata/' + trackId;
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        if (http.responseText) {
                            resolve(JSON.parse(http.responseText));
                        } else {
                            resolve(false);
                        }
                    }
                };
                http.open('GET', _serviceURL, true);
                http.setRequestHeader('Content-type', 'application/json');
                http.send();
            } catch (ex) {
                reject(ex);
            }
        });
    }

    // Get missing Social Info
    getMissingSocialFields(options) {
        return new Promise((resolve, reject) => {
            try {
                const http = new XMLHttpRequest();
                const _serviceURL = environment.loginBaseURL + '/public-srv/public/trackinfo/' + options.requestId + '/' + options.trackId;
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        if (http.responseText) {
                            resolve(JSON.parse(http.responseText));
                        } else {
                            resolve(false);
                        }
                    }
                };
                http.open('GET', _serviceURL, true);
                http.setRequestHeader('Content-type', 'application/json');
                http.send();
            } catch (ex) {
                reject(ex);
            }
        });
    }

    // Get Scope Consent Details
    getScopeConsentDetails(options) {
        return new Promise((resolve, reject) => {
            try {
                this.cidaas
                    .getScopeConsentDetails(options)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((ex) => {
                        console.log(ex);
                        reject(ex);
                    });
            } catch (ex) {
                reject(ex);
            }
        });
    }

    // Accept Scope Consent
    acceptScopeConsent(options) {
        return new Promise((resolve, reject) => {
            try {
                this.cidaas
                    .acceptScopeConsent(options)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((ex) => {
                        console.log(ex);
                        reject(ex);
                    });
            } catch (ex) {
                reject(ex);
            }
        });
    }

    // Continue when user accept scope consent
    scopeConsentContinue(user) {
        this.cidaas.scopeConsentContinue(user);
    }

    // Delete User Authentication (If enabled)
    deleteVerification(options, token) {
        return new Promise((resolve, reject) => {
            try {
                const http = new XMLHttpRequest();

                const _serviceURL =
                    this.env.authority +
                    '/verification-srv/v2/setup/users/configured/remove/' +
                    options.verificationType.toString().toUpperCase();
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        if (http.responseText) {
                            resolve(JSON.parse(http.responseText));
                        } else {
                            resolve(false);
                        }
                    }
                };
                http.open('DELETE', _serviceURL, true);
                http.setRequestHeader('Content-type', 'application/json');
                http.setRequestHeader('access_token', token);
                http.send(JSON.stringify(options));
            } catch (ex) {
                reject(ex);
            }
        });
    }

    // Get Password policy information
    getPasswordPolicy() {
        return new Promise((resolve, reject) => {
            try {
                const http = new XMLHttpRequest();

                const _serviceURL = this.env.authority + '/password-policy-srv/policy';
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        if (http.responseText) {
                            resolve(JSON.parse(http.responseText));
                        } else {
                            resolve(false);
                        }
                    }
                };
                http.open('GET', _serviceURL, true);
                http.setRequestHeader('Content-type', 'application/json');
                http.send();
            } catch (ex) {
                reject(ex);
            }
        });
    }

    // Logout User
    logoutUser(token) {
        this.cidaas.logoutUser({
            accessToken: token,
        });
    }

    // Get Registration Setup
    getRegistrationSetup(user) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .getRegistrationSetup({
                    requestId: user.requestId,
                    acceptlanguage: user.acceptlanguage,
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Register User
    registerUser(user, headers) {
        return new Promise((resolve, reject) => {
            this.cidaasRegisterWithTrackId(user, headers)
                .then((response) => {
                    resolve(response);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    cidaasRegisterWithTrackId(options, headers) {
        return new Promise((resolve, reject) => {
            try {
                const http = new XMLHttpRequest();
                // @ts-ignore
                let _serviceURL = (window as any).webAuthSettings.authority + '/users-srv/register';
                if (options.invite_id) {
                    _serviceURL = _serviceURL + '?invite_id=' + options.invite_id;
                }
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        if (http.responseText) {
                            resolve(JSON.parse(http.responseText));
                        } else {
                            resolve(false);
                        }
                    }
                };
                http.open('POST', _serviceURL, true);
                http.setRequestHeader('Content-type', 'application/json');
                http.setRequestHeader('requestId', headers.requestId);
                if (headers.trackId) {
                    http.setRequestHeader('trackId', headers.trackId);
                }
                if (headers.captcha) {
                    http.setRequestHeader('captcha', headers.captcha);
                }
                if (headers.acceptlanguage) {
                    http.setRequestHeader('accept-language', headers.acceptlanguage);
                }
                if (headers.bot_captcha_response) {
                    http.setRequestHeader('bot_captcha_response', headers.bot_captcha_response);
                }
                http.send(JSON.stringify(options));
            } catch (ex) {
                reject(ex);
            }
        });
    }

    progressiveRegisterUser(options, headerOptions, captcha) {
        return new Promise((resolve, reject) => {
            try {
                const http = new XMLHttpRequest();
                const _serviceURL = environment.loginBaseURL + '/login-srv/progressive/update/user';
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        if (http.responseText) {
                            resolve(JSON.parse(http.responseText));
                        } else {
                            resolve(false);
                        }
                    }
                };
                http.open('POST', _serviceURL, true);
                http.setRequestHeader('Content-type', 'application/json');
                http.setRequestHeader('requestId', headerOptions.requestId);
                http.setRequestHeader('trackId', headerOptions.trackId);
                http.setRequestHeader('captcha', captcha);
                http.send(JSON.stringify(options));
            } catch (ex) {
                reject(ex);
            }
        });
    }

    // Initialize Reset password Process
    initiateResetPassword(user) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .initiateResetPassword({
                    email: user.email,
                    processingType: user.processingType,
                    requestId: user.requestId,
                    resetMedium: 'email',
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Reset Password
    resetPassword(user) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .resetPassword({
                    password: user.password,
                    confirmPassword: user.confirmPassword,
                    exchangeId: user.exchangeId,
                    resetRequestId: user.resetRequestId,
                })
                .then((response) => {
                    resolve({});
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Setup TOTP Authentication for User
    setupTOTP(user, token) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .setupTOTP(
                    {
                        logoUrl: user.logoUrl,
                        deviceInfo: {
                            deviceId: user.deviceId,
                        },
                    },
                    token
                )
                .then((response) => {
                    resolve(response.data);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Initialize TOTP Authentication for User
    initiateTOTP(user) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .initiateMFAV2(user)
                .then((response) => {
                    resolve(response);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Enroll TOTP Authentication for User
    enrollTOTP(user, token) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .enrollTOTP(user, token)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Authenticate TOTP from User
    authenticateTOTP(user) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .authenticateMFAV2(user)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Initialize E-mail Authentication for User
    initiateEmail(user) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .initiateMFAV2(user)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Enroll E-mail Authentication for User
    authenticateEmail(user) {
        return new Promise((resolve, reject) => {
            this.cidaas
                .authenticateMFAV2(user)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    }

    // Continue after MFA Completion
    mfaContinue(user) {
        this.cidaas.mfaContinue({
            statusId: user.status_id,
            trackId: user.trackId,
            requestId: user.requestId,
            sub: user.sub,
            verificationType: user.verificationType,
        });
    }

    registerContinue(trackId) {
        this.cidaas.loginAfterRegister({ trackId });
    }

    // get phone number verification
    getPhoneNumberVerification(phoneNumber) {
        return new Promise((resolve, reject) => {
            try {
                const http = new XMLHttpRequest();
                const url = `${environment.phoneVerification}/v1/validate/${phoneNumber}?apikey=${environment.phoneVerificationApiKey}`;
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        if (http.responseText) {
                            resolve(JSON.parse(http.responseText));
                        } else {
                            resolve(false);
                        }
                    }
                };
                http.open('GET', url, true);
                http.setRequestHeader('Content-type', 'application/json');
                http.send();
            } catch (ex) {
                reject(ex);
            }
        });
    }
}
