<div class="container">
    <div class="title">
        {{'reset-success.title' | translate}}
    </div>
    <div class="subtitle">
        {{'reset-success.subtitle' | translate}}
    </div>
    <button mat-button mat-flat-button color="primary" class="button blue mt-48" type="button" [routerLink]="['/login']" [queryParams]="{requestId: requestId}">
        {{'reset-success.login' | translate}}
    </button>
</div>
