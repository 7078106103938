<div class="column-container">
    <div class="container">
        <div class="title">
            {{ 'forgot-password.title' | translate }}
        </div>
        <div class="subtitle">
            {{ 'forgot-password.subtitle' | translate }}
        </div>
        <form [formGroup]="resetPasswordForm" class="form">
            <mat-form-field color="accent" appearance="outline" class="form-field">
                <mat-label>{{ 'forgot-password.email' | translate }}</mat-label>
                <input
                    matInput
                    type="email"
                    formControlName="email"
                    name="email"
                    id="email"
                    autocomplete="off"
                    [class.input-error]="
                        (resetPasswordForm.controls.email.value && !resetPasswordForm.controls.email.valid) ||
                        (!resetPasswordForm.controls.email.value && !resetPasswordForm.controls.email.valid && submitted)
                    "
                    class="input-field"
                />
                <mat-hint></mat-hint>
                <mat-error class="error" *ngIf="resetPasswordForm.controls.email.value && !resetPasswordForm.controls.email.valid">
                    {{ 'forgot-password.invalid-email' | translate }}
                </mat-error>
                <mat-error
                    class="error"
                    *ngIf="!resetPasswordForm.controls.email.value && !resetPasswordForm.controls.email.valid && submitted"
                >
                    {{ 'forgot-password.invalid-email' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-error class="error" *ngIf="isForgetError">
                {{ 'forgot-password.generic-error' | translate }}
            </mat-error>

            <button
                [disabled]="!resetPasswordForm.controls.email.valid"
                mat-button
                mat-flat-button
                color="primary"
                class="button blue mt-48"
                type="submit"
                (click)="onReset(resetPasswordForm, resetPasswordForm.valid)"
            >
                {{ 'forgot-password.reset' | translate }}
            </button>
            <button
                mat-button
                mat-flat-button
                color="accent"
                class="button white mt-16"
                [routerLink]="['/login']"
                [queryParams]="{ requestId: requestId }"
            >
                {{ 'forgot-password.cancel' | translate }}
            </button>
        </form>
    </div>
</div>
