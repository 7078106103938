<div class="container">
    <img src="../../../assets/img/Email_Confirmed.svg" alt="Email Confirmed" class="image" />
    <div class="title">E-Mail-Adresse erfolgreich bestätigt</div>

    <div class="content">Vielen Dank, du wirst in einigen Sekunden weitergeleitet.</div>

    <div class="return-to-login">
        <button
            class="button"
            mat-flat-button
            color="accent"
            [queryParams]="{ requestId: requestId }"
            [routerLink]="['/login-credentials']"
        >
            Zurück zum Login
        </button>
    </div>
</div>
