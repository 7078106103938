<div class="column-container">
    <div class="container">
        <div class="title">Log In</div>
        <form [formGroup]="loginFormGroup" class="form">
            <mat-form-field color="accent" appearance="outline" class="form-field">
                <mat-label>{{ 'login.email' | translate }}</mat-label>
                <input type="email" matInput formControlName="email" name="email" id="email" autocomplete="username" />
                <mat-hint></mat-hint>
                <mat-error *ngIf="loginFormGroup.controls.email.value && !loginFormGroup.controls.email.valid">
                    {{ 'login.invalid-email' | translate }}
                </mat-error>
                <mat-error *ngIf="!loginFormGroup.controls.email.value && !loginFormGroup.controls.email.valid && submitted">
                    {{ 'login.invalid-email' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field color="accent" appearance="outline" class="form-field">
                <mat-label>{{ 'login.password' | translate }}</mat-label>
                <input type="password" matInput formControlName="password" name="password" id="password" />
                <mat-hint></mat-hint>
                <mat-error *ngIf="!loginFormGroup.controls.password.valid && submitted">
                    {{ 'login.invalid-password' | translate }}
                </mat-error>
            </mat-form-field>

            <button mat-button mat-flat-button color="primary" type="submit" (click)="login(loginFormGroup)">
                {{ 'login.login' | translate }}
            </button>

            <button [routerLink]="['/reset', requestId]" class="button" mat-flat-button color="accent">
                {{ 'login.forgot-password' | translate }}
            </button>

            <mat-error class="error center mt-16" *ngIf="isLoginError">{{ errorMessage | translate }}</mat-error>
        </form>
    </div>
</div>
