import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldConfig, PasswordPolicy } from '../../models';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    NgForm,
    Validators,
} from '@angular/forms';
import { CidaasService, LoaderService, TranslationService } from '../../services';
import { LocaleHelper, PasswordPolicyValidation } from 'src/app/config';
import { InputFieldValidator } from 'src/app/config/input-validation';
import { environment } from '../../../environments/environment';

import { MobileNumberValidator } from '../../config/mobile-number-validator';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
    selector: 'app-extra',
    templateUrl: './extra.component.html',
    styleUrls: ['./extra.component.scss'],
})
export class ExtraComponent implements OnInit {
    requestId: string;
    trackId: string;
    registrationFields: FieldConfig[] = [];
    registrationForm: UntypedFormGroup;
    acceptLanguage: string;
    registrationFieldPayload = {
        acceptlanguage: '',
        requestId: '',
    };
    registrationFieldResponse: any[] = [];
    passwordPolicy: PasswordPolicy;
    minlength = 0;
    maxlength = 100;
    afterRegisterResponse: any;
    isRegisterError = false;
    registerError = '';
    countryNumber: string;
    specialCharactersError: string;
    digitsRequiredError: string;
    lowerAndUpperCaseError: string;
    countryName: string;
    customFields: any = {};
    checkboxOptions: any[] = [];
    submitted = false;
    missingFields: string[] = [];
    publicInfo: any = {};
    viewType: string;
    phoneNumberUtil = PhoneNumberUtil.getInstance();

    phoneCodeInputValue = '+49';
    phoneNumberInputValue: string;
    valid: boolean;
    matcher = new (class MyErrorStateMatcher implements ErrorStateMatcher {
        isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
            return control.touched && !!form.form.controls['mobile_number'].errors;
        }
    })();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private loader: LoaderService,
        private activatedRoute: ActivatedRoute,
        private registrationService: CidaasService,
        private translate: TranslationService
    ) {
        window.document.title = 'Additional Information';
        this.acceptLanguage = LocaleHelper.getBrowserLanguage();
        this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
        this.trackId = this.activatedRoute.snapshot.queryParams.trackId;
        this.viewType = this.activatedRoute.snapshot.queryParams.view_type;
    }

    ngOnInit() {
        if (!this.viewType) {
            this.viewType = 'register';
        }
        this.createRegistrationForm();
        if (this.trackId && this.requestId) {
            if (this.viewType === 'register') {
                this.getMissingSocialFields();
            } else {
                this.getMissingFields();
            }
        } else {
            this.router.navigate(['/welcome']);
            console.error('Invalid trackId or trackId not found !');
        }
        this.registrationFieldPayload = {
            acceptlanguage: this.acceptLanguage,
            requestId: this.requestId,
        };
        this.getPasswordPolicy();
    }

    // Create New Registration Form
    createRegistrationForm() {
        this.registrationForm = this.formBuilder.group({});
    }

    // Get Password policy
    getPasswordPolicy() {
        this.loader.showLoader();
        this.registrationService
            .getPasswordPolicy()
            .then((response: any) => {
                this.passwordPolicy = response.data;
                if (this.passwordPolicy.minimumLength > 0) {
                    this.minlength = this.passwordPolicy.minimumLength;
                }
                if (this.passwordPolicy.maximumLength > 0) {
                    this.maxlength = this.passwordPolicy.maximumLength;
                }
                this.loader.hideLoader();
                this.getRegistrationFields();
            })
            .catch(() => {
                this.loader.hideLoader();
                this.getRegistrationFields();
            });
    }

    // get missing fields lists
    getMissingFields() {
        this.loader.showLoader();
        this.registrationService
            .getMissingFields(this.trackId)
            .then((response: any) => {
                this.loader.hideLoader();
                if (response.data) {
                    this.missingFields = response.data.meta_data.missing_fields;
                    if (response.data.userInfo) {
                        this.publicInfo = response.data.userInfo;
                    }
                } else {
                    this.missingFields = [];
                }
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
            });
    }

    getMissingSocialFields() {
        this.loader.showLoader();
        const options: any = {};
        options.requestId = this.requestId;
        options.trackId = this.trackId;
        this.registrationService
            .getMissingSocialFields(options)
            .then((response: any) => {
                this.loader.hideLoader();
                if (response.data && response.data.missingFields) {
                    this.missingFields = response.data.missingFields;
                    if (response.data.userInfo) {
                        this.publicInfo = response.data.userInfo;
                    }
                } else {
                    this.missingFields = [];
                }
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
            });
    }

    getRegistrationFields() {
        this.loader.showLoader();
        this.registrationService
            .getRegistrationSetup(this.registrationFieldPayload)
            .then((response: any) => {
                this.loader.hideLoader();
                this.registrationFieldResponse = response?.sort((a, b) => a.order - b.order);
                if (this.registrationFieldResponse && this.registrationFieldResponse.length > 0) {
                    const actualFieldsList: any[] = [];
                    this.registrationFieldResponse.forEach((field) => {
                        this.missingFields.forEach((missingfield) => {
                            if (field.fieldKey === missingfield) {
                                actualFieldsList.push(field);
                            }
                        });
                    });
                    this.registrationFields = [];
                    if (actualFieldsList && actualFieldsList.length > 0) {
                        actualFieldsList.forEach((field, index) => {
                            this.registrationFields.push(this.setFormFields(field, index));
                        });
                        this.registrationFields.forEach((field) => {
                            this.createControl(field);
                        });
                    }
                } else {
                    this.loader.hideLoader();
                    this.router.navigate(['/welcome'], { queryParams: { error: 'registration fields not found' } });
                }

                this.registrationForm.get('availability_euerzuhause')?.setValue(3);
                this.registrationForm.updateValueAndValidity({ emitEvent: true });
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
            });
    }

    // Assign value to Form Fields
    setFormFields(field: any, index: number): FieldConfig {
        const tempField: FieldConfig = {} as FieldConfig;
        tempField.validations = {};
        tempField.inputType = field.dataType;
        tempField.type = field.fieldType;
        tempField.key = field.fieldKey;
        tempField.internal = field.internal ? field.internal : false;
        tempField.readOnly = field.readOnly;
        tempField.label = field.localeText ? (field.localeText.name ? field.localeText.name : 'Label' + index) : 'Label' + index;
        tempField.name = field.localeText ? (field.localeText.name ? field.localeText.name : 'Name' + index) : 'Name' + index;
        tempField.value = '';
        if (field.dataType === 'SELECT') {
            tempField.options = field.localeText
                ? field.localeText.attributes
                    ? field.localeText.attributes
                    : field.localeText.attributes
                : '';
        } else if (field.dataType === 'CHECKBOX') {
            tempField.options = field.localeText
                ? field.localeText.attributes
                    ? field.localeText.attributes
                    : field.localeText.attributes
                : '';
            tempField.options.forEach((val) => {
                this.checkboxOptions.push(val);
            });
        } else if (field.dataType === 'RADIO') {
            tempField.options = field.localeText
                ? field.localeText.attributes
                    ? field.localeText.attributes
                    : field.localeText.attributes
                : '';
            tempField.options.forEach((val) => {
                this.checkboxOptions.push(val);
            });
        } else if (field.dataType === 'CONSENT') {
            tempField.collections = field.localeText
                ? field.localeText.consentLabel
                    ? field.localeText.consentLabel
                    : field.localeText.consentLabel
                : null;
        }
        if (field.required) {
            tempField.isRequired = true;
            tempField.validations.required = {
                name: 'required',
                validator: Validators.required,
            };
        }
        if (field.dataType === 'MOBILE') {
            tempField.validations.mobile = {
                name: 'mobile',
                validator: MobileNumberValidator.validateMobile(),
                message: this.translate.getTranslatedMessage('Please enter a valid mobile number with country code'),
            };
        }
        if (field.dataType === 'EMAIL') {
            tempField.validations.email = {
                name: 'email',
                validator: Validators.email,
            };
        }
        if (field.fieldDefinition && field.fieldDefinition.maxLength && field.fieldKey !== 'password') {
            tempField.validations.maxlength = {
                name: 'maxlength',
                validator: Validators.maxLength(field.fieldDefinition.maxLength),
                maxlength: field.fieldDefinition.maxLength,
            };
        } else if (field.fieldKey === 'password' && this.passwordPolicy.minimumLength && this.passwordPolicy.maximumLength > 0) {
            tempField.validations.minlength = {
                name: 'minlength',
                validator: Validators.minLength(this.passwordPolicy.minimumLength),
                minlength: this.passwordPolicy.minimumLength,
            };
            tempField.validations.maxlength = {
                name: 'maxlength',
                validator: Validators.maxLength(this.passwordPolicy.maximumLength),
                maxLength: this.passwordPolicy.maximumLength,
            };
        } else if (
            field.fieldDefinition &&
            field.fieldDefinition.maxLength &&
            field.key === 'password' &&
            (this.passwordPolicy.maximumLength <= 0 || !this.passwordPolicy.maximumLength)
        ) {
            tempField.validations.maxlength = {
                name: 'maxlength',
                validator: Validators.maxLength(field.fieldDefinition.maxLength),
                maxlength: field.fieldDefinition.maxLength,
            };
        }
        if (field.localeText && field.localeText.matchWith && field.fieldDefinition && field.fieldDefinition.matchWith) {
            tempField.validations.matchPassword = {
                name: 'matchPassword',
                validator: 'matchPassword',
                matchwith: field.fieldDefinition.matchWith,
            };
        }
        return tempField;
    }

    // Create Dynamic Control
    createControl(field: any) {
        if (field.inputType !== 'CHECKBOX') {
            const control = this.formBuilder.control(field.value, this.bindValidations(field.key, field.validations));
            this.registrationForm.addControl(field.key, control);
        } else {
            let checkboxControl: any;
            if (field.isRequired) {
                checkboxControl = new UntypedFormArray([], InputFieldValidator.validateCheckBox());
            } else {
                checkboxControl = new UntypedFormArray([]);
            }
            field.options.map(() => {
                const control = new UntypedFormControl();
                checkboxControl.push(control);
            });
            this.registrationForm.addControl(field.key, checkboxControl);
        }
    }

    // Bind Validations to Control
    bindValidations(key: string, validations: any) {
        if (key !== 'password' && validations) {
            const validList = [];
            Object.keys(validations).forEach((validationsKey) => {
                if (validations[validationsKey].validator !== 'matchPassword') {
                    validList.push(validations[validationsKey].validator);
                }
            });
            return Validators.compose(validList);
        } else if (key === 'password' && validations) {
            const validList = [];
            Object.keys(validations).forEach((validationsKey) => {
                if (validations[validationsKey].validator !== 'matchPassword') {
                    validList.push(validations[validationsKey].validator);
                }
            });
            if (this.passwordPolicy) {
                if (this.passwordPolicy.lowerAndUpperCase) {
                    validList.push(PasswordPolicyValidation.requireLowerandUpper());
                }
                if (this.passwordPolicy.noOfDigits > 0) {
                    validList.push(PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits));
                }
                if (this.passwordPolicy.noOfSpecialChars > 0) {
                    validList.push(PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars));
                }
                return Validators.compose(validList);
            } else {
                return Validators.compose(validList);
            }
        }
    }

    register(formValue: any, valid: boolean) {
        if (valid) {
            this.loader.showLoader();
            const formValues = formValue.value;
            const payload = this.publicInfo;
            const reqHeaders: any = {};
            const customFields: any = {};
            this.submitted = true;
            this.registrationFields.forEach((field) => {
                field.value = formValues[field.key];
                if (Array.isArray(field.value)) {
                    field.value.forEach((val, i) => {
                        if (!val) {
                            field.value.splice(i, 1);
                        } else {
                            field.value[i] = field.options[i].value;
                        }
                    });
                }
                if (field.key === 'NeaSmart_Installer' && Array.isArray(field.value) && field.value.length > 0) {
                    payload['roles'] = ['User_NeaSmart_Installer'];
                }
                if (field.type === 'SYSTEM') {
                    payload[field.key] = field.value;
                } else {
                    customFields[field.key] = {
                        key: field.key,
                        value: field.value,
                        readOnly: field.readOnly,
                        dataType: field.inputType,
                        internal: field.internal,
                        lastUpdateFrom: 'SELF',
                    };
                }
            });
            payload['customFields'] = customFields;
            if (this.publicInfo && this.publicInfo.provider) {
                payload['provider'] = this.publicInfo.provider;
            } else {
                payload['provider'] = 'self';
            }
            reqHeaders['requestId'] = this.requestId;
            if (this.trackId) {
                reqHeaders['trackId'] = this.trackId;
            }

            if (this.viewType === 'login') {
                this.progressiveRegister(payload, reqHeaders);
            } else {
                this.normalRegister(payload, reqHeaders);
            }

            // this.registrationService.progressiveRegisterUser(payload, reqHeaders, '').then((response: any) => {
            //     this.loader.hideLoader();
            //     if (response && response.data) {
            //         const loginFormElement = document.getElementsByName('register_user')[0] as HTMLFormElement;
            //         loginFormElement.action = environment.loginBaseURL + '/login-srv/precheck/continue/' + this.trackId;
            //         loginFormElement.method = 'post';
            //         loginFormElement.submit();
            //     } else {
            //         this.submitted = false;
            //         if (response.status === 409) {
            //             this.isRegisterError = true;
            //             this.registerError = this.translate.getTranslatedMessage('extra.user-exists');
            //         } else {
            //             this.isRegisterError = true;
            //             this.registerError = this.translate.getTranslatedMessage('extra.error');
            //         }
            //     }
            // }).catch((ex) => {
            //     this.loader.hideLoader();
            //     console.log(ex);
            //     this.submitted = false;
            //     this.isRegisterError = true;
            //     this.registerError = this.translate.getTranslatedMessage('extra.error');
            // });
        }
    }

    progressiveRegister(payload, reqHeaders) {
        this.registrationService
            .progressiveRegisterUser(payload, reqHeaders, undefined)
            .then((response: any) => {
                this.loader.hideLoader();
                if (response && response.data) {
                    // this.afterRegisterResponse = response.data;
                    const loginFormElement = document.getElementsByName('register_user')[0] as HTMLFormElement;
                    loginFormElement.action = environment.loginBaseURL + '/login-srv/precheck/continue/' + this.trackId;
                    loginFormElement.method = 'post';
                    loginFormElement.submit();
                } else {
                    this.submitted = false;
                    if (response.status === 409) {
                        this.isRegisterError = true;
                        this.registerError = this.translate.getTranslatedMessage('User Already Exist');
                    } else {
                        this.isRegisterError = true;
                        this.registerError = this.translate.getTranslatedMessage('Error Occured');
                    }
                }
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
                this.submitted = false;
                this.isRegisterError = true;
                this.registerError = this.translate.getTranslatedMessage('Error Occured');
            });
    }

    normalRegister(payload, reqHeaders) {
        this.registrationService
            .registerUser(payload, reqHeaders)
            .then((response: any) => {
                this.loader.hideLoader();
                if (response && response.data) {
                    this.afterRegisterResponse = response.data;
                    if (this.publicInfo.email && this.afterRegisterResponse && this.afterRegisterResponse.email_verified === false) {
                        this.router.navigate(['/verification'], {
                            queryParams: {
                                requestId: this.requestId,
                                sub: this.afterRegisterResponse.sub,
                            },
                        });
                    } else {
                        this.registrationService.registerContinue(response.data.trackId);
                        // this.router.navigate(['/reg_success'], {queryParams: {'requestId': this.requestId}});
                    }
                } else {
                    this.submitted = false;
                    if (response.status === 409) {
                        this.isRegisterError = true;
                        this.registerError = this.translate.getTranslatedMessage('register.user-exists');
                    } else {
                        this.isRegisterError = true;
                        this.registerError = this.translate.getTranslatedMessage('Error Occured');
                    }
                }
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
                this.submitted = false;
                this.isRegisterError = true;
                this.registerError = this.translate.getTranslatedMessage('Error Occured');
            });
    }

    phoneNumberChange(field) {
        this.registrationForm.get(field).setValue(this.phoneCodeInputValue + this.phoneNumberInputValue);
    }

    onPwdChange(field) {
        const phoneInputValue = this.phoneCodeInputValue + this.phoneNumberInputValue;
        this.registrationService
            .getPhoneNumberVerification(phoneInputValue)
            .then((response: any) => {
                this.valid = !!response.valid;
                if (!this.valid) {
                    this.registrationForm.get(field).setErrors({ errorPhoneValidation: true });
                } else if (this.registrationForm.get(field).hasError('errorPhoneValidation')) {
                    this.registrationForm.get(field).setErrors({ errorPhoneValidation: false });
                }
            })
            .catch((ex) => {
                console.log(ex);
            });
    }
}
