<div class="column-container">
    <div class="container">
        <div class="title">
            {{ 'extra.title' | translate }}
            <div class="mandatory-field">
                {{ 'register.mandatory-field' | translate }}
            </div>
        </div>
        <mat-error class="subtitle" *ngIf="isRegisterError">
            {{ registerError | translate }}
        </mat-error>
        <form *ngIf="registrationFields && registrationFields.length > 0" [formGroup]="registrationForm" name="register_user">
            <div *ngFor="let field of registrationFields">
                <mat-form-field *ngIf="field.key === 'given_name'" color="accent" appearance="outline" class="form-field">
                    <mat-label>{{ 'register.name' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [formControlName]="field.key"
                        [name]="field.key"
                        [id]="field.key"
                        [class.input-error]="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                        class="input-field"
                        required
                    />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="registrationForm.controls[field.key].value && !registrationForm.controls[field.key].valid">
                        {{ 'register.required-name' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="!registrationForm.controls[field.key].value && !registrationForm.controls[field.key].valid && submitted"
                    >
                        {{ 'register.required-name' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="field.key === 'family_name'" color="accent" appearance="outline" class="form-field">
                    <mat-label>{{ 'register.family-name' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [formControlName]="field.key"
                        [name]="field.key"
                        [id]="field.key"
                        [class.input-error]="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                        class="input-field"
                    />
                    <mat-hint></mat-hint>
                    <mat-error
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ 'register.required-family-name' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field
                    *ngIf="field.key !== 'family_name' && field.key !== 'given_name' && field.inputType === 'TEXT'"
                    color="accent"
                    appearance="outline"
                    class="form-field"
                >
                    <mat-label>{{ field.name }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [formControlName]="field.key"
                        [name]="field.key"
                        [id]="field.key"
                        [class.input-error]="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                        class="input-field"
                    />
                    <mat-hint></mat-hint>
                    <mat-error
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ 'register.required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="field.inputType === 'EMAIL'" color="accent" appearance="outline" class="form-field">
                    <mat-label>{{ 'register.email' | translate }}</mat-label>
                    <input
                        matInput
                        type="email"
                        autocomplete="username"
                        [formControlName]="field.key"
                        [name]="field.key"
                        [class.input-error]="
                            (registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.email) ||
                            (!registrationForm.controls[field.key].valid &&
                                submitted &&
                                registrationForm.controls[field.key].errors?.required)
                        "
                        [id]="field.key"
                        class="input-field"
                    />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.email">
                        {{ 'register.invalid-email' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ 'register.required-email' | translate }}
                    </mat-error>
                </mat-form-field>

                <ng-container *ngIf="field.inputType === 'MOBILE'">
                    <mat-form-field color="accent" appearance="outline" class="mobile-code">
                        <mat-label>{{ 'register.phone-country' | translate }}</mat-label>
                        <mat-select
                            [(ngModel)]="phoneCodeInputValue"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="phoneNumberChange(field.key)"
                            (selectionChange)="onPwdChange(field.key)"
                        >
                            <mat-option
                                *ngFor="let code of phoneNumberUtil.getSupportedRegions()"
                                [value]="'+' + phoneNumberUtil.getCountryCodeForRegion(code)"
                            >
                                {{ code }} +{{ phoneNumberUtil.getCountryCodeForRegion(code) }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field color="accent" appearance="outline" class="mobile-number">
                        <mat-label>{{ 'register.phone-number' | translate }}</mat-label>
                        <input
                            matInput
                            [errorStateMatcher]="matcher"
                            type="text"
                            [(ngModel)]="phoneNumberInputValue"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="phoneNumberChange(field.key)"
                            [class.input-error]="
                                !registrationForm.controls[field.key].valid &&
                                submitted &&
                                registrationForm.controls[field.key].errors?.required
                            "
                            class="input-field"
                            (change)="onPwdChange(field.key)"
                            required
                        />
                        <mat-hint></mat-hint>
                        <mat-error
                            *ngIf="
                                !registrationForm.controls[field.key].valid &&
                                submitted &&
                                registrationForm.controls[field.key].errors?.required
                            "
                        >
                            {{ 'register.required' | translate }}
                        </mat-error>
                        <mat-error
                            class="error"
                            *ngIf="registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.invalidMobile"
                        >
                            {{ 'register.incorrect-mobile' | translate }}
                        </mat-error>
                        <mat-error class="error" *ngIf="registrationForm.controls[field.key]?.hasError('errorPhoneValidation')">
                            {{ 'register.incorrect-mobile' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <input type="hidden" [formControlName]="field.key" [name]="field.key" [id]="field.key" />
                </ng-container>

                <mat-form-field *ngIf="field.inputType === 'PASSWORD'" color="accent" appearance="outline" class="form-field">
                    <mat-label>
                        <ng-container *ngIf="field.key === 'password'">
                            {{ 'register.password' | translate }}
                        </ng-container>
                        <ng-container *ngIf="field.key === 'password_echo'">
                            {{ 'register.password-repeat' | translate }}
                        </ng-container>
                    </mat-label>
                    <input
                        matInput
                        type="password"
                        autocomplete="new-password"
                        [formControlName]="field.key"
                        [name]="field.key"
                        [id]="field.key"
                        [class.input-error]="
                            (!registrationForm.controls[field.key].valid &&
                                submitted &&
                                registrationForm.controls[field.key].errors?.required) ||
                            (registrationForm.controls[field.key].value &&
                                !registrationForm.controls[field.key].errors?.lowerAndUpperRequired &&
                                !registrationForm.controls[field.key].errors?.MinDigitsRequired &&
                                !registrationForm.controls[field.key].errors?.SpecialCharsRequired &&
                                registrationForm.controls[field.key].errors?.minlength) ||
                            (registrationForm.controls[field.key].value &&
                                !registrationForm.controls[field.key].errors?.lowerAndUpperRequired &&
                                !registrationForm.controls[field.key].errors?.MinDigitsRequired &&
                                !registrationForm.controls[field.key].errors?.SpecialCharsRequired &&
                                registrationForm.controls[field.key].errors?.maxlength) ||
                            (registrationForm.controls[field.key].value &&
                                registrationForm.controls[field.key].errors?.lowerAndUpperRequired) ||
                            (registrationForm.controls[field.key].value &&
                                !registrationForm.controls[field.key].errors?.lowerAndUpperRequired &&
                                registrationForm.controls[field.key].errors?.MinDigitsRequired) ||
                            (registrationForm.controls[field.key].value &&
                                !registrationForm.controls[field.key].errors?.lowerAndUpperRequired &&
                                !registrationForm.controls[field.key].errors?.MinDigitsRequired &&
                                registrationForm.controls[field.key].errors?.SpecialCharsRequired) ||
                            (registrationForm.controls[field.key].value &&
                                !registrationForm.controls[field.key].errors?.lowerAndUpperRequired &&
                                !registrationForm.controls[field.key].errors?.MinDigitsRequired &&
                                registrationForm.controls[field.key].errors?.SpecialCharsRequired) ||
                            (registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.matchPassword)
                        "
                        class="input-field"
                    />
                    <mat-hint></mat-hint>
                    <mat-error
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        <ng-container *ngIf="field.key === 'password'">
                            {{ 'register.required-password' | translate }}
                        </ng-container>
                        <ng-container *ngIf="field.key === 'password_echo'">
                            {{ 'register.required-password-repeat' | translate }}
                        </ng-container>
                    </mat-error>
                    <mat-error
                        *ngIf="
                            registrationForm.controls[field.key].value &&
                            !registrationForm.controls[field.key].errors?.lowerAndUpperRequired &&
                            !registrationForm.controls[field.key].errors?.MinDigitsRequired &&
                            !registrationForm.controls[field.key].errors?.SpecialCharsRequired &&
                            registrationForm.controls[field.key].errors?.minlength
                        "
                    >
                        {{ 'register.min-length-password' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            registrationForm.controls[field.key].value &&
                            !registrationForm.controls[field.key].errors?.lowerAndUpperRequired &&
                            !registrationForm.controls[field.key].errors?.MinDigitsRequired &&
                            !registrationForm.controls[field.key].errors?.SpecialCharsRequired &&
                            registrationForm.controls[field.key].errors?.maxlength
                        "
                    >
                        {{ 'register.max-length-password' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.lowerAndUpperRequired
                        "
                    >
                        {{ 'register.lower-upper-case-password' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            registrationForm.controls[field.key].value &&
                            !registrationForm.controls[field.key].errors?.lowerAndUpperRequired &&
                            registrationForm.controls[field.key].errors?.MinDigitsRequired
                        "
                    >
                        {{ 'register.digits-required-password' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            registrationForm.controls[field.key].value &&
                            !registrationForm.controls[field.key].errors?.lowerAndUpperRequired &&
                            !registrationForm.controls[field.key].errors?.MinDigitsRequired &&
                            registrationForm.controls[field.key].errors?.SpecialCharsRequired
                        "
                    >
                        {{ 'register.special-chars-required-password' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="registrationForm.controls[field.key].value && registrationForm.controls[field.key].errors?.matchPassword"
                    >
                        {{ 'register.not-match-password' | translate }}
                    </mat-error>
                </mat-form-field>

                <div *ngIf="field.inputType === 'CHECKBOX'">
                    <div formArrayName="{{ field.key }}" *ngFor="let chk_box of registrationForm.get(field.key).controls; let i = index">
                        <label class="input-element">
                            <mat-checkbox [formControlName]="i" [required]="field.validations.required && !field.value">
                                {{ field.options[i].value }}
                            </mat-checkbox>
                        </label>
                    </div>
                    <div
                        class="error"
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ field.validations?.required?.message }}
                    </div>
                </div>

                <div *ngIf="field.key === 'availability_euerzuhause'" class="form-field radio">
                    <div>{{ field.label }}</div>
                    <mat-radio-group
                        class="availability"
                        formControlName="{{ field.key }}"
                        [required]="field.validations.required && !field.value"
                    >
                        <mat-radio-button
                            class="radio-label"
                            *ngFor="let option of field.options; let last = last"
                            [value]="option.value"
                            [checked]="last"
                            >{{ option.key }}</mat-radio-button
                        >
                    </mat-radio-group>
                    <div
                        class="error"
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ field.validations?.required?.message }}
                    </div>
                </div>

                <div *ngIf="field.inputType === 'CONSENT'">
                    <label class="input-element">
                        <mat-checkbox formControlName="{{ field.key }}" [required]="field.validations.required && !field.value">
                            <span [innerHTML]="field.collections.label | safeHtml"></span>
                        </mat-checkbox>
                    </label>
                    <div
                        class="error"
                        *ngIf="
                            !registrationForm.controls[field.key].valid &&
                            submitted &&
                            registrationForm.controls[field.key].errors?.required
                        "
                    >
                        {{ 'register.accept-terms-required' | translate }}
                    </div>
                </div>
            </div>
            <button
                [disabled]="!registrationForm.valid"
                mat-button
                mat-flat-button
                color="primary"
                type="submit"
                (click)="register(registrationForm, registrationForm.valid)"
            >
                {{ 'register.create-account' | translate }}
            </button>
        </form>
    </div>
</div>
