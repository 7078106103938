import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CidaasService, TranslationService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordPolicy } from '../../models';
import { PasswordPolicyValidation, PasswordValidation } from '../../config';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    requestId: string;
    passwordPolicy: PasswordPolicy;
    resetPasswordForm: UntypedFormGroup;
    minlength = 0;
    maxlength = 100;
    exchangeId: string;
    rprq: string;
    digitsRequiredError: string;
    submitted = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private resetPasswordService: CidaasService
    ) {
        window.document.title = 'Reset Password';
        this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
        this.exchangeId = this.activatedRoute.snapshot.queryParams.exchangeid;
        this.rprq = this.activatedRoute.snapshot.queryParams.rprq;
    }

    ngOnInit() {
        this.createNewResetPasswordForm();
        this.getPasswordPolicy();
    }

    // create new reset password form
    createNewResetPasswordForm() {
        this.resetPasswordForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            passwordEcho: ['', [Validators.required]],
        });
        this.resetPasswordForm.setValidators(PasswordValidation.matchPassword);
    }

    // Get Password policy
    getPasswordPolicy() {
        this.resetPasswordService
            .getPasswordPolicy()
            .then((response: any) => {
                this.passwordPolicy = response.data;
                if (this.passwordPolicy.minimumLength > 0) {
                    this.minlength = this.passwordPolicy.minimumLength;
                }
                if (this.passwordPolicy.maximumLength > 0) {
                    this.maxlength = this.passwordPolicy.maximumLength;
                }
                this.setPasswordPolicyValidators();
            })
            .catch((ex) => {
                console.log(ex);
            });
    }

    // Set Password policy validators to Control
    setPasswordPolicyValidators() {
        if (this.passwordPolicy.lowerAndUpperCase) {
            if (this.minlength && this.maxlength > 0) {
                if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                        ]);
                } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireLowerandUpper(),
                        ]);
                }
            } else {
                if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                        ]);
                } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            PasswordPolicyValidation.requireLowerandUpper(),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else {
                    this.resetPasswordForm.get('password').setValidators(PasswordPolicyValidation.requireLowerandUpper());
                }
            }
        } else {
            if (this.minlength && this.maxlength > 0) {
                if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                        ]);
                } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            Validators.minLength(this.minlength),
                            Validators.maxLength(this.maxlength),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([Validators.minLength(this.minlength), Validators.maxLength(this.maxlength)]);
                }
            } else {
                if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators([
                            PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits),
                            PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars),
                        ]);
                } else if (this.passwordPolicy.noOfDigits > 0 && this.passwordPolicy.noOfSpecialChars <= 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators(PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits));
                } else if (this.passwordPolicy.noOfDigits <= 0 && this.passwordPolicy.noOfSpecialChars > 0) {
                    this.resetPasswordForm
                        .get('password')
                        .setValidators(PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars));
                } else {
                }
            }
        }
    }

    changePassword(formValue: any, valid: boolean) {
        this.submitted = true;
        if (valid) {
            const formValues = formValue.value;
            const user: any = {};
            user.password = formValues.password;
            user.confirmPassword = formValues.passwordEcho;
            user.exchangeId = this.exchangeId;
            user.resetRequestId = this.rprq;
            this.resetPasswordService
                .resetPassword(user)
                .then(() => {
                    this.submitted = false;
                    this.router.navigate(['/forgot_success'], { queryParams: { requestId: this.requestId } });
                })
                .catch(() => {
                    this.submitted = false;
                    console.log('Reset Failed');
                });
        } else {
        }
    }
}
