import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from './services';
import { ParseurlService } from './services';
import { StorageHelper } from './config';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    browserLang = '';
    show = false;
    uiLocales: string;
    currentLocale: any;
    urlLocale: any;
    clientId: string;
    supportedLanguages = [
        { code: 'en', label: 'English' },
        { code: 'de', label: 'Deutsch' },
    ];
    selectedLanguage: string;

    constructor(
        private translate: TranslateService,
        private communicationService: CommunicationService,
        private parseUrl: ParseurlService
    ) {
        this.currentLocale = StorageHelper.getData('uiLocales');
        this.urlLocale = this.parseUrl.findValueInFragment(location.search, 'uiLocales');
        this.clientId = this.parseUrl.findValueInFragment(location.search, 'clientId');
        if (this.clientId) {
            StorageHelper.saveData('clientId', this.clientId);
        }
    }

    ngAfterViewInit(): void {
        this.communicationService.on('show', (load: boolean) => {
            this.show = load;
        });
    }

    setLanguage(lang): void {
        this.translate.use(lang);
        StorageHelper.saveData('language', lang);
    }

    ngOnInit(): void {
        this.translate.use('de');
        // this.browserLang = this.translate.getBrowserLang();
        // const savedLanguage = StorageHelper.getData('language');
        // if (savedLanguage) {
        //     this.translate.use(savedLanguage);
        //     this.selectedLanguage = savedLanguage;
        // } else {
        //     if (!this.currentLocale) {
        //         this.uiLocales = this.urlLocale;
        //     } else {
        //         if (this.currentLocale !== this.urlLocale) {
        //             this.uiLocales = this.urlLocale;
        //         } else {
        //             this.uiLocales = this.currentLocale;
        //         }
        //     }
        //     if (this.uiLocales && this.uiLocales !== '') {
        //         StorageHelper.saveData('uiLocales', this.uiLocales);
        //         const list: string[] = this.uiLocales.split(',');
        //         if (list && list.length > 0) {
        //             const locale = list[0];
        //             if (locale.search('en') !== -1) {
        //                 this.translate.use('en');
        //                 this.selectedLanguage = 'en';
        //             } else if (locale.search('de') !== -1) {
        //                 this.translate.use('de');
        //                 this.selectedLanguage = 'de';
        //             } else {
        //                 this.translate.use('en');
        //                 this.selectedLanguage = 'en';
        //             }
        //         }
        //     } else {
        //         if (this.browserLang.search('de') !== -1) {
        //             this.translate.use('de');
        //             this.selectedLanguage = 'de';
        //         } else {
        //             this.translate.use('en');
        //             this.selectedLanguage = 'en';
        //         }
        //     }
        // }
    }
}
