<div class="container">
    <div class="title">
        {{'consent.title' | translate}}
    </div>
    <div class="subtitle">
        {{'consent.subtitle' | translate}}
    </div>
    <ul class="scope_consent_ul">
        <li *ngFor="let scopes of consentScopes" class="scope_consent_li">
            <label class="scope_consent_label">{{scopes.scopeKey}}:</label>
            <span class="scope_consent_span">{{scopes.description}}</span>
        </li>
    </ul>
    <div class="consent-content">{{consentResponse?.content}}</div>
    <button class="button blue mt-48" (click)="acceptConsent()">
        {{'consent.accept' | translate}}
    </button>
    <button class="button mt-16" [routerLink]="['/login']"
            [queryParams]="{requestId: requestId}">
        {{'consent.cancel' | translate}}
    </button>
</div>

