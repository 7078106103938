<div class="column-container">
    <div class="container">
        <img src="../../../assets/img/Email_Unconfirmed.svg" alt="Email Unconfirmed" class="image" />
        <ng-container *ngIf="step === 1">
            <div class="title">Bitte bestätige deine Telefonnummer</div>

            <div class="content" *ngIf="!smsSent && shouldSendSMS">
                Senden der Aktivierungs-SMS<br />
                Bitte warten...
            </div>

            <div class="content" *ngIf="smsSent || !shouldSendSMS">
                Wir haben einen SMS-Code an
                <span class="bold">{{ userPhoneNumber }}</span>
                geschickt. Bitte gib diesen Code in das Feld ein und klicke auf bestätigen.
                <mat-form-field color="accent" appearance="outline" class="form-field">
                    <mat-label> SMS Code </mat-label>
                    <input matInput type="text" [(ngModel)]="smsCode" maxlength="6" />
                </mat-form-field>
            </div>

            <div class="buttons">
                <button mat-flat-button color="accent" [disabled]="smsCode?.length !== 6" (click)="verifySMSCode(smsCode)">
                    {{ 'verification.verify-code' | translate }}
                </button>
                <button mat-flat-button color="primary" (click)="shouldSendSMS = true; initiateAccountVerificationSMS()">
                    {{ 'verification.resend-code' | translate }}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="step === 2">
            <div class="title">Fast geschafft! Bitte bestätige deine E-Mail-Adresse</div>

            <div class="content" *ngIf="shouldSendEmail && !userEmail">
                Senden der Aktivierungs-E-Mail<br />
                Bitte warten...
            </div>

            <div class="content" *ngIf="shouldSendEmail && userEmail">
                Wir haben soeben eine E-Mail an
                <span class="bold">{{ userEmail }}</span>
                geschickt. Bitte klick auf den Link in dieser Mail, um deine E-Mail Adresse zu bestätigen.
            </div>
            <div class="content" *ngIf="!shouldSendEmail">Aktivierungs-E-Mail bereits gesendet.</div>

            <div class="buttons">
                <!--                <button mat-flat-button color="accent" [queryParams]="{ requestId: requestId }" [routerLink]="['/login-credentials']">-->
                <!--                    {{ 'message.already-sent-to-login' | translate }}-->
                <!--                </button>-->
                <button mat-flat-button color="primary" (click)="shouldSendEmail = true; initiateAccountVerificationEmail()">
                    {{ 'message.already-sent-resend' | translate }}
                </button>
            </div>
        </ng-container>
    </div>
</div>
