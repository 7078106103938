import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldConfig, PasswordPolicy } from '../../models';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    NgForm,
    Validators,
} from '@angular/forms';
import { CidaasService, LoaderService, TranslationService } from '../../services';
import { LocaleHelper, PasswordPolicyValidation, PasswordValidation } from 'src/app/config';
import { InputFieldValidator } from 'src/app/config/input-validation';
import { Md5 } from 'ts-md5/dist/md5';

import { MobileNumberValidator } from '../../config/mobile-number-validator';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ErrorStateMatcher } from '@angular/material/core';
import { registerGAEvent } from 'src/app/services/common.service';
import { isArray } from 'rxjs/internal-compatibility';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    requestId: string;
    registrationFields: FieldConfig[] = [];
    registrationForm: UntypedFormGroup;
    acceptLanguage: string;
    registrationFieldPayload = {
        acceptlanguage: '',
        requestId: '',
    };
    registrationFieldResponse: any[] = [];
    passwordPolicy: PasswordPolicy;
    minlength = 0;
    maxlength = 100;
    afterRegisterResponse: any;
    isRegisterError = false;
    registerError = '';
    digitsRequiredError: string;
    customFields: any = {};
    checkboxOptions: any[] = [];
    submitted = false;
    phoneNumberUtil = PhoneNumberUtil.getInstance();

    phoneCodeInputValue = '+49';
    phoneNumberInputValue: string;
    valid: boolean;
    matcher = new (class MyErrorStateMatcher implements ErrorStateMatcher {
        isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
            return control.touched && !!form.form.controls['mobile_number'].errors;
        }
    })();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private loader: LoaderService,
        private activatedRoute: ActivatedRoute,
        private registrationService: CidaasService,
        private translate: TranslationService
    ) {
        activatedRoute.queryParams.subscribe((v) => {
            if (v?.customHashFragment === 'login') {
                router.navigate(['login-credentials'], { queryParamsHandling: 'merge' });
            }
        });

        window.document.title = 'Register';
        this.acceptLanguage = LocaleHelper.getBrowserLanguage();
        this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
    }

    ngOnInit() {
        registerGAEvent('event', 'data query');
        registerGAEvent('page_view', 'registration_shown', { pageLocation: `/${this.activatedRoute.snapshot?.url[0]?.path}` });
        this.createRegistrationForm();
        if (!this.requestId) {
            this.router.navigate(['/login-credentials']);
        } else {
            this.registrationFieldPayload = {
                acceptlanguage: this.acceptLanguage,
                requestId: this.requestId,
            };
            this.getPasswordPolicy();
        }
    }

    // Create New Registration Form
    createRegistrationForm() {
        // min 2 chars, with accents and numbers, no special characters, no space
        const pattern = '^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9_.+-]*(?:[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ][a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9_.+-]*){2,}$';
        this.registrationForm = this.formBuilder.group({
            given_name: ['', [Validators.required, Validators.pattern(pattern)]],
            family_name: ['', [Validators.required, Validators.pattern(pattern)]],
        });
    }

    // Get Password policy
    getPasswordPolicy() {
        this.loader.showLoader();
        this.registrationService
            .getPasswordPolicy()
            .then((response: any) => {
                this.passwordPolicy = response.data;
                if (this.passwordPolicy.minimumLength > 0) {
                    this.minlength = this.passwordPolicy.minimumLength;
                }
                if (this.passwordPolicy.maximumLength > 0) {
                    this.maxlength = this.passwordPolicy.maximumLength;
                }
                this.loader.hideLoader();
                this.getRegistrationFields();
            })
            .catch(() => {
                this.loader.hideLoader();
                this.getRegistrationFields();
            });
    }

    // Get Registration Field Setup
    getRegistrationFields() {
        this.loader.showLoader();
        this.registrationService
            .getRegistrationSetup(this.registrationFieldPayload)
            .then((response: any) => {
                this.loader.hideLoader();
                if (response && response.length > 0) {
                    response.forEach((field) => {
                        if (field.fieldKey === 'given_name') {
                            field.order = 1;
                        } else if (field.fieldKey === 'family_name') {
                            field.order = 2;
                        } else if (field.fieldKey === 'username') {
                            field.order = 3;
                        } else if (field.fieldKey === 'email') {
                            field.order = 4;
                        } else if (field.fieldKey === 'password') {
                            field.order = 5;
                        } else if (field.fieldKey === 'password_echo') {
                            field.order = 6;
                        } else if (field.fieldKey === 'availability_euerzuhause') {
                            field.order = 7;
                        }
                    });
                }
                this.registrationFieldResponse = response.sort((a, b) => a.order - b.order);
                if (this.registrationFieldResponse && this.registrationFieldResponse.length > 0) {
                    this.registrationFields = [];
                    this.registrationFieldResponse.forEach((field, index) => {
                        this.registrationFields.push(this.setFormFields(field, index));
                    });
                    this.registrationFields.forEach((field) => {
                        this.createControl(field);
                    });
                    this.registrationForm.setValidators(PasswordValidation.matchPassword);
                } else {
                    this.loader.hideLoader();
                    this.router.navigate(['/welcome'], {
                        queryParams: { error: 'registration fields not found' },
                    });
                }
                this.registrationForm.get('availability_euerzuhause')?.setValue(3);
                this.registrationForm.updateValueAndValidity({ emitEvent: true });
            })
            .catch((ex) => {
                this.loader.hideLoader();
                console.log(ex);
            });
    }

    setFormFields(field: any, index: number): FieldConfig {
        const tempField: FieldConfig = {} as FieldConfig;
        tempField.validations = {};
        tempField.inputType = field.dataType;
        tempField.type = field.fieldType;
        tempField.key = field.fieldKey;
        tempField.internal = field.internal ? field.internal : false;
        tempField.readOnly = field.readOnly;
        tempField.label = field.localeText ? (field.localeText.name ? field.localeText.name : 'Label' + index) : 'Label' + index;
        tempField.name = field.localeText ? (field.localeText.name ? field.localeText.name : 'Name' + index) : 'Name' + index;
        if (tempField.name === 'Given Name') {
            tempField.name = 'First Name';
        }
        tempField.value = '';
        if (field.dataType === 'SELECT') {
            tempField.options = field.localeText
                ? field.localeText.attributes
                    ? field.localeText.attributes
                    : field.localeText.attributes
                : '';
        } else if (field.dataType === 'CHECKBOX') {
            tempField.options = field.localeText
                ? field.localeText.attributes
                    ? field.localeText.attributes
                    : field.localeText.attributes
                : '';
            tempField.options.forEach((val) => {
                this.checkboxOptions.push(val);
            });
        } else if (field.dataType === 'RADIO') {
            tempField.options = field.localeText
                ? field.localeText.attributes
                    ? field.localeText.attributes
                    : field.localeText.attributes
                : '';
            tempField.options.forEach((val) => {
                this.checkboxOptions.push(val);
            });
        } else if (field.dataType === 'CONSENT') {
            tempField.collections = field.localeText
                ? field.localeText.consentLabel
                    ? field.localeText.consentLabel
                    : field.localeText.consentLabel
                : null;
        }
        if (field.required) {
            tempField.isRequired = true;
            tempField.validations.required = {
                name: 'required',
                validator: Validators.required,
            };
        }
        if (field.dataType === 'MOBILE') {
            tempField.validations.mobile = {
                name: 'mobile',
                validator: MobileNumberValidator.validateMobile(),
                message: this.translate.getTranslatedMessage('Please enter a valid mobile number with country code'),
            };
        }
        if (field.dataType === 'EMAIL') {
            tempField.validations.email = {
                name: 'email',
                validator: Validators.email,
            };
        }
        if (field.fieldDefinition && field.fieldDefinition.maxLength && field.fieldKey !== 'password') {
            tempField.validations.maxlength = {
                name: 'maxlength',
                validator: Validators.maxLength(field.fieldDefinition.maxLength),
                maxlength: field.fieldDefinition.maxLength,
            };
        } else if (field.fieldKey === 'password' && this.passwordPolicy.minimumLength && this.passwordPolicy.maximumLength > 0) {
            tempField.validations.minlength = {
                name: 'minlength',
                validator: Validators.minLength(this.passwordPolicy.minimumLength),
                minlength: this.passwordPolicy.minimumLength,
            };
            tempField.validations.maxlength = {
                name: 'maxlength',
                validator: Validators.maxLength(this.passwordPolicy.maximumLength),
                maxLength: this.passwordPolicy.maximumLength,
            };
        } else if (
            field.fieldDefinition &&
            field.fieldDefinition.maxLength &&
            field.key === 'password' &&
            (this.passwordPolicy.maximumLength <= 0 || !this.passwordPolicy.maximumLength)
        ) {
            tempField.validations.maxlength = {
                name: 'maxlength',
                validator: Validators.maxLength(field.fieldDefinition.maxLength),
                maxlength: field.fieldDefinition.maxLength,
            };
        }
        if (field.localeText && field.localeText.matchWith && field.fieldDefinition && field.fieldDefinition.matchWith) {
            tempField.validations.matchPassword = {
                name: 'matchPassword',
                validator: 'matchPassword',
                matchwith: field.fieldDefinition.matchWith,
            };
        }
        return tempField;
    }

    createControl(field: any) {
        if (field.inputType !== 'CHECKBOX') {
            const control = this.formBuilder.control(field.value, this.bindValidations(field.key, field.validations));
            this.registrationForm.addControl(field.key, control);
        } else {
            let checkboxControl: any;
            if (field.isRequired) {
                checkboxControl = new UntypedFormArray([], InputFieldValidator.validateCheckBox());
            } else {
                checkboxControl = new UntypedFormArray([]);
            }
            field.options.map(() => {
                const control = new UntypedFormControl();
                checkboxControl.push(control);
            });
            this.registrationForm.addControl(field.key, checkboxControl);
        }
    }

    bindValidations(key: string, validations: any) {
        if (key !== 'password' && validations) {
            const validList = [];
            Object.keys(validations).forEach((validationsKey) => {
                if (validations[validationsKey].validator !== 'matchPassword') {
                    validList.push(validations[validationsKey].validator);
                }
            });
            return Validators.compose(validList);
        } else if (key === 'password' && validations) {
            const validList = [];
            Object.keys(validations).forEach((validationsKey) => {
                if (validations[validationsKey].validator !== 'matchPassword') {
                    validList.push(validations[validationsKey].validator);
                }
            });
            if (this.passwordPolicy) {
                if (this.passwordPolicy.lowerAndUpperCase) {
                    validList.push(PasswordPolicyValidation.requireLowerandUpper());
                }
                if (this.passwordPolicy.noOfDigits > 0) {
                    validList.push(PasswordPolicyValidation.requireDigits(this.passwordPolicy.noOfDigits));
                }
                if (this.passwordPolicy.noOfSpecialChars > 0) {
                    validList.push(PasswordPolicyValidation.requireSpecialChars(this.passwordPolicy.noOfSpecialChars));
                }
                return Validators.compose(validList);
            } else {
                return Validators.compose(validList);
            }
        }
    }

    register(formValue: any, valid: boolean) {
        this.submitted = true;
        if (valid) {
            console.log(this.requestId);
            this.loader.showLoader();
            const formValues = formValue.value;
            const payload: any = {};
            const customFields: any = {};
            this.submitted = true;
            this.registrationFields.forEach((field) => {
                field.value = formValues[field.key];
                if (isArray(field.value)) {
                    field.value.forEach((val, i) => {
                        if (!val) {
                            field.value.splice(i, 1);
                        } else {
                            field.value[i] = field.options[i].value;
                        }
                    });
                }
                if (field.key === 'NeaSmart_Installer' && isArray(field.value) && field.value.length > 0) {
                    payload['roles'] = ['User_NeaSmart_Installer'];
                }
                if (field.type === 'SYSTEM') {
                    payload[field.key] = field.value;
                } else {
                    customFields[field.key] = {
                        key: field.key,
                        value: field.value,
                        readOnly: field.readOnly,
                        dataType: field.inputType,
                        internal: field.internal,
                        lastUpdateFrom: 'SELF',
                    };
                }
            });
            payload['customFields'] = customFields;
            payload['provider'] = 'self';
            const headers: any = {
                requestId: this.requestId,
                'accept-language': this.acceptLanguage,
            };
            this.registrationService
                .registerUser(payload, headers)
                .then((response: any) => {
                    this.loader.hideLoader();
                    if (response && response.data) {
                        this.afterRegisterResponse = response.data;
                        console.log(JSON.stringify(response.data));
                        this.submitted = false;
                        this.router.navigate(['/verification'], {
                            queryParams: {
                                trackId: this.afterRegisterResponse.trackId,
                                requestId: this.requestId,
                                sub: this.afterRegisterResponse.sub,
                                usernameHash: Md5.hashStr(formValues['email']),
                            },
                        });
                    } else {
                        this.submitted = false;
                        this.isRegisterError = true;
                        if (response.error && response.error.code) {
                            this.registerError = this.translate.getTranslatedMessage('register.error-codes.' + response.error.code);
                        } else {
                            this.registerError = this.translate.getTranslatedMessage('register.error');
                        }
                    }
                })
                .catch((ex) => {
                    this.loader.hideLoader();
                    console.log(ex);
                    this.submitted = false;
                    this.isRegisterError = true;
                    this.registerError = this.translate.getTranslatedMessage('register.error');
                });
        } else {
        }
    }

    onPhoneNumberChange(field) {
        this.phoneNumberInputValue = this.phoneNumberInputValue?.replace(/^0+/, '');
        this.valid = false;
        this.phoneNumberChange(field);
        const phoneInputValue = this.phoneCodeInputValue + this.phoneNumberInputValue;
        this.registrationService
            .getPhoneNumberVerification(phoneInputValue)
            .then((response: any) => {
                this.valid = !!response.valid;
                if (!this.valid) {
                    this.registrationForm.get(field).setErrors({ errorPhoneValidation: true });
                } else if (this.registrationForm.get(field).hasError('errorPhoneValidation')) {
                    this.registrationForm.get(field).setErrors({ errorPhoneValidation: false });
                }
            })
            .catch((ex) => {
                console.log(ex);
            });
    }

    phoneNumberChange(field) {
        if (this.phoneNumberInputValue?.startsWith('0')) {
            this.registrationForm.get(field).setErrors({ errorStartingZero: true });
            return;
        } else if (this.registrationForm.get(field).hasError('errorStartingZero')) {
            this.registrationForm.get(field).setErrors({ errorStartingZero: null });
        }
        this.registrationForm.get(field).setValue(this.phoneCodeInputValue + this.phoneNumberInputValue);
    }
}
