import { AbstractControl } from '@angular/forms';

export class PasswordValidation {
    static matchPassword(ac: AbstractControl) {
        const password = ac.get('password'); // to get value in input tag
        const confirmPassword = ac.get('password_echo'); // to get value in input tag
        if (password?.value !== confirmPassword?.value) {
            confirmPassword?.setErrors({ matchPassword: true });
        } else {
            delete confirmPassword.errors?.matchPassword;
            confirmPassword?.setErrors(Object.keys(confirmPassword.errors || {}).length ? confirmPassword.errors : null);
            return null;
        }
    }
}
