import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CidaasService, TranslationService } from '../../services';
import { DOCUMENT } from '@angular/common';
import { registerGAEvent } from 'src/app/services/common.service';
import { StorageHelper } from '../../config';
import { VerificationComponent } from '../verification/verification.component';

@Component({
    selector: 'app-login-credentials',
    templateUrl: './login-credentials.component.html',
    styleUrls: ['./login-credentials.component.scss'],
})
export class LoginCredentialsComponent implements OnInit {
    public loginFormGroup: UntypedFormGroup;
    requestId: string;
    errorMessage: string;
    error: string;
    isLoginError = false;
    submitted = false;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private loginService: CidaasService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslationService
    ) {
        window.document.title = 'Login';
    }

    ngOnInit() {
        this.requestId = this.activatedRoute.snapshot.queryParams.requestId;
        registerGAEvent('page_view', 'login_with_email_password_shown', {
            pageLocation: `/${this.activatedRoute.snapshot?.url[0]?.path}`,
            client_id: this.requestId,
        });
        this.error = this.activatedRoute.snapshot.queryParams.error;
        if (!this.requestId) {
            this.getRequestId();
        }
        if (this.error) {
            this.isLoginError = true;
            this.errorMessage = this.translate.getTranslatedMessage('login.error');
        }
        this.createNewLoginFormGroup();
    }

    // Get Request Id (If Request Id is not came in URL)
    getRequestId() {
        // if (!this.activatedRoute.snapshot.queryParams['requestId']) {
        const requestId = StorageHelper.getData(VerificationComponent.lastUsedRequestIdStorageKey);
        if (requestId) {
            this.requestId = requestId;
        } else {
        }

        // console.log('Direct Entry...redirecting!');
        // this.router.ngOnDestroy();
        // window.location.href = environment.authRequestRedirect;
        // }
        // this.loginService
        //     .getRequestId()
        //     .then((response: any) => {
        //         if (response.success && response.data) {
        //             this.requestId = response.data.requestId;
        //         } else if (!response.success && response.error) {
        //             this.isLoginError = true;
        //             this.errorMessage = response.error.error;
        //         } else {
        //             this.isLoginError = true;
        //             this.errorMessage = 'requestId cannot be empty or null';
        //         }
        //     })
        //     .catch((error: any) => {
        //         console.log(error);
        //     });
    }

    // Auto Prefill fix
    fixAutoFill(usr: Event, pwd: string) {
        if (usr) {
            this.loginFormGroup.get('email').setValue((usr.target as HTMLInputElement).value);
        }
        if (pwd) {
            this.loginFormGroup.get('password').setValue(pwd);
        }
    }

    // Create New Login Form
    createNewLoginFormGroup() {
        this.loginFormGroup = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
        });
    }

    // Login
    login(formValue: any) {
        this.submitted = true;
        if (formValue.valid) {
            const loginPayload = formValue.value;
            loginPayload['requestId'] = this.requestId;
            this.submitted = false;
            this.loginService.login(loginPayload);
        } else {
            console.error('Login Form Invalid!');
        }
    }

    socialLogin(provider: any) {
        window.location.href = environment.loginBaseURL + '/login-srv/social/login/' + provider + '/' + this.requestId;
    }
}
